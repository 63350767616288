body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#root {
  margin: 50px;
}

.large-title {
  font-size: 48px;
  line-height: normal;
}
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.log-out {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.header-group {
  flex-direction: column;
  height: auto;
  display: flex;
}
.header-group > .header-subtitle {
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.field-gap {
  margin: 30px;
}

.success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.success-modal > .success-title {
  font-size: 30px;
  line-height: normal;
  margin-top: 10px;
}

.app-table-item {
  font-size: 16px;
}

.app-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  gap: 10px;
}
.prompt-card-header {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  gap: 10px;
}
.active-prompt-pill {
  background: #ff4d01;
  border-radius: 20px;
  padding: 0px 10px;
  color: white;
  font-size: 14px;
}

.prompt-section {
  display: flex;
  align-items: flex-start;
}

.prompt-section > p {
  flex: 0 0 20%;
}

.prompt-section > .large-textarea {
  flex: 0 0 80%;
  height: 20em;
}

.cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.toaster {
  z-index: 10000;
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.prompt-header-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-table-row {
  cursor: pointer;
}

.app-table-row:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.few-shot-group {
  border: 2px solid #d3d3d3;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
}
.few-shot-group-title {
  font-weight: 600;
}
.few-shot-group-header {
  display: flex;
  justify-content: space-between;
}

.icon-style {
  cursor: pointer;
}
